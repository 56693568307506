<template>
  <div id="users" class="grid">
    <div class="pg-header">
      <h2>Användare</h2>
    </div>
    <div id="alertBox" role="alert">
      <transition-group name="fade">
        <div v-show="showSuccessAlertBox" key="ok" class="alert alert-success">
          <strong>{{ message }}</strong>
        </div>
        <div v-show="showDangerAlertBox" key="nok" class="alert alert-danger">
          <strong>{{ message }}</strong>
        </div>
      </transition-group>
    </div>
    <div class="pg-table">
      <div v-if="errorMessage === '' && !loading">
        <div class="pg-table-toolbar">
          <div class="pg-search-input">
            <input v-model="search" type="text" class="searchInput" placeholder="Sök" />
          </div>
          <div class="pg-add-button">
            <button class="btn-primary" @click="addUser">Lägg till</button>
          </div>
        </div>
        <div v-if="errorMessage === '' && !loading && users.length === 0" class="pg-center">
          <p>Hittar inga användare</p>
        </div>
        <table id="tblUsers" class="content-table content-table-striped">
          <thead>
            <tr>
              <th>Namn</th>
              <th>Användarnamn</th>
              <th>Roll</th>
            </tr>
          </thead>
          <tbody v-if="!loading && users.length > 0">
            <tr v-for="user in filtredUsers" :key="user.user_id" class="clickable" @click="editUser(user.user_id)">
              <td>{{ user.full_name }}</td>
              <td>{{ user.username }}</td>
              <td>{{ user.role_description }}</td>
            </tr>
          </tbody>
        </table>
      </div>
      <div v-if="loading && errorMessage === ''" class="pg-center">
        <Spinner></Spinner>
      </div>
      <div v-if="errorMessage !== ''" class="pg-center">
        <p class="errorMessageText">{{ errorMessage }}</p>
      </div>
    </div>
  </div>
</template>

<script>
import { mapActions } from 'vuex';
import Spinner from '@/components/utils/Spinner';
export default {
  name: 'Users',
  components: {
    Spinner
  },
  props: {
    addUpdateOK: {
      type: Boolean,
      default: false
    },
    hasAddUpdate: {
      type: Boolean,
      default: false
    },
    action: {
      type: String,
      default: ''
    }
  },
  data() {
    return {
      loading: false,
      errorMessage: '',
      users: [],
      search: '',
      message: '',
      showSuccessAlertBox: false,
      showDangerAlertBox: false
    };
  },
  computed: {
    filtredUsers() {
      const trimmedSearch = this.search.toLowerCase().trim();
      return this.users.filter((user) => {
        return user.full_name.toLowerCase().match(trimmedSearch) || user.username.toLowerCase().match(trimmedSearch) || user.role_name.toLowerCase().match(trimmedSearch);
      });
    }
  },
  async mounted() {
    this.loading = true;
    await this.fetchMyUsers();
    this.loading = false;
  },
  created() {
    if (this.addUpdateOK && this.hasAddUpdate) {
      if (this.action == 'SAVE') {
        this.message = 'Användaren är nu sparad';
      }
      if (this.action == 'DELETE') {
        this.message = 'Användaren är nu raderad';
      }
      setTimeout(() => (this.showSuccessAlertBox = true), 0);
      setTimeout(() => (this.showSuccessAlertBox = false), 3000);
    }
    if (!this.addUpdateOK && this.hasAddUpdate) {
      if (this.action == 'SAVE') {
        this.message = 'Användaren kunde inte sparas';
      }
      if (this.action == 'DELETE') {
        this.message = 'Användaren kunde inte raderas';
      }
      setTimeout(() => (this.showDangerAlertBox = true), 0);
      setTimeout(() => (this.showDangerAlertBox = false), 3000);
    }
  },
  updated() {},
  beforeUpdate() {},
  beforeDestroy() {},
  destroyed() {},
  methods: {
    // ...mapActions(["users/fetchUsers"])
    ...mapActions({
      fetchUsers: 'users/fetchUsers'
    }),
    editUser: function (user_id) {
      this.$router.replace({
        name: 'User',
        params: {
          userId: user_id
        }
      });
    },
    addUser() {
      this.$router.replace({
        name: 'User',
        params: {
          userId: 0
        }
      });
    },

    async fetchMyUsers() {
      this.errorMessage = '';
      this.users = [];

      try {
        this.users = await this.fetchUsers();
      } catch (error) {
        //console.log("fetchMyUsers ERROR");
        // TODO : FIX ERROR LOG
        if (error.response) {
          if (error.response.status === 401) {
            try {
              await this.$store.dispatch('logout');
            } catch (err) {
              //   console.error(err);
              // TODO : FIX ERROR LOG
            } finally {
              this.$router.replace({
                name: 'Login'
              });
            }
          } else {
            this.errorMessage = 'Kunde inte hämta användare';
          }
        }
      }
    }
  }
};
</script>

<style lang="scss" scoped></style>
